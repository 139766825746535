// src/pages/Home.js

import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Code, Cpu, Zap } from 'lucide-react';

const Home = () => {
  return (
    <main className="relative min-h-screen overflow-hidden">
      {/* Responsive Background */}
      <div
        className="fixed inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage: "url('/worldbackground.png')",
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>
      <div className="fixed inset-0 bg-gradient-to-br from-purple-900/70 to-indigo-900/70 z-0"></div>

      {/* Content */}
      <div className="relative z-1 container mx-auto px-4 py-16 sm:py-20 md:py-24 pt-24 sm:pt-28 md:pt-32">
        <section className="text-center mb-8 sm:mb-16">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 text-white">Shaping the Future with AI</h2>
          <p className="text-lg sm:text-xl mb-6 sm:mb-8 text-white">
            Innovative software solutions powered by cutting-edge technology
          </p>
          <a
            href="mailto:tugadot@gmail.com?subject=Tugadot%20-%20Tell%20me%20more"
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-full transition-colors inline-flex items-center"
          >
            Get Started
            <ChevronRight className="ml-2" size={16} />
          </a>
        </section>

        <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 mb-8 sm:mb-16">
          <div className="bg-white bg-opacity-10 p-4 sm:p-6 rounded-lg backdrop-blur-sm">
            <Cpu className="text-purple-400 mb-3 sm:mb-4" size={36} />
            <h3 className="text-xl sm:text-2xl font-semibold mb-2 text-white">AI Integration</h3>
            <p className="text-sm sm:text-base text-gray-200">
              Harness the power of artificial intelligence to transform your business processes.
            </p>
          </div>
          <div className="bg-white bg-opacity-10 p-4 sm:p-6 rounded-lg backdrop-blur-sm">
            <Code className="text-purple-400 mb-3 sm:mb-4" size={36} />
            <h3 className="text-xl sm:text-2xl font-semibold mb-2 text-white">Custom Software</h3>
            <p className="text-sm sm:text-base text-gray-200">
              Tailor-made solutions designed to meet your unique business needs.
            </p>
          </div>
          <div className="bg-white bg-opacity-10 p-4 sm:p-6 rounded-lg backdrop-blur-sm">
            <Zap className="text-purple-400 mb-3 sm:mb-4" size={36} />
            <h3 className="text-xl sm:text-2xl font-semibold mb-2 text-white">Rapid Prototyping</h3>
            <p className="text-sm sm:text-base text-gray-200">
              Quick idea validation and development to keep you ahead of the competition.
            </p>
          </div>
        </section>

        <section className="text-center">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-3 sm:mb-4 text-white">Ready to Innovate?</h2>
          <p className="text-lg sm:text-xl mb-6 sm:mb-8 text-white">Let's create the future together.</p>
          <Link
            to="/contact"
            className="bg-transparent border-2 border-purple-400 text-purple-400 hover:bg-purple-400 hover:text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-full transition-colors"
          >
            Contact Us
          </Link>
        </section>
      </div>
    </main>
  );
};

export default Home;